import { prepareParams } from './helperFunctions/prepareParams';
import { sendForm } from './helperFunctions/sendForm';

export function initCheckoutRedirect() {
    const checkoutContainer = document.querySelector('[data-checkout-container]');

    if (checkoutContainer) {
        return;
    }

    const checkoutForms = document.querySelectorAll('[data-checkout-form]');
    [...checkoutForms].forEach((checkoutForm) => {
        checkoutForm.addEventListener('submit', function (e) {
            e.preventDefault();

            const url = checkoutForm.getAttribute('action');
            const formBody = prepareParams(checkoutForm);

            sendForm(url, formBody)
                .then(function (response) {
                    return response.json();
                })
                .then(function (response) {
                    if (response.url) {
                        top.location.href = response.url;
                    }
                })
                .catch(function (error) {
                    return '';
                });
        });
    });
}
