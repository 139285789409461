export function prepareParams(checkoutForm) {
    const details = {};
    let formBody = [];
    const radioButton = checkoutForm.querySelector('input[type="radio"]:checked');
    const hiddenInputs = checkoutForm.querySelectorAll('input[type="hidden"]');
    if (!radioButton) {
        details['product'] = ' ';
    } else {
        details['product'] = radioButton.value;
    }

    hiddenInputs.forEach((input) => {
        details[input.name] = input.value;
    });

    if (details.product === 'geof_7_w2app_7') {
        details.coupon = '';
    }

    for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + '=' + encodedValue);
    }

    formBody = formBody.join('&');

    return formBody;
}
