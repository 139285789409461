import { prepareParams } from './helperFunctions/prepareParams';
import { sendForm } from './helperFunctions/sendForm';

export function initCheckoutIframe() {
    const checkoutContainer = document.querySelector('[data-checkout-container]');

    if (!checkoutContainer) {
        return;
    }

    const checkoutForms = document.querySelectorAll('[data-checkout-form]');
    [...checkoutForms].forEach((checkoutForm) => {
        const button = checkoutForm.querySelector('[data-checkout-form-button]');
        button?.classList.remove('disabled');

        checkoutForm.addEventListener('submit', function (e) {
            e.preventDefault();
            removeAllChildNodes(checkoutContainer);

            const url = checkoutForm.getAttribute('action');
            const formBody = prepareParams(checkoutForm);

            sendForm(url, formBody)
                .then(function (response) {
                    return response.json();
                })
                .then(function (response) {
                    if (response.url) {
                        const encodedUrl = response.url.replace(/&/g, '&amp;');
                        const checkoutFrame = `<iframe allow="payment" style="width: 100%; border: 0; height: 100%;" src="${encodedUrl}"></iframe>`;
                        checkoutContainer.insertAdjacentHTML('afterbegin', checkoutFrame);
                    }
                })
                .catch(function (error) {
                    return '';
                });
        });
    });

    function removeAllChildNodes(parent) {
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }
    }
}
